@mixin border-radius($radius) {
  border-radius: $radius;
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
}

:root {
  --max-height: 100dvh;
}

@supports(height: 100dvh) {
  :root {
    --maxHeight: 100vh;
  }
}

html {
  height: -webkit-fill-available;
}

body {
  background: linear-gradient(
    184deg,
    rgba(78, 109, 244, 0.3) 0%,
    rgba(78, 109%, 244, 0) 100%),
    radial-gradient(ellipse at bottom, rgb(var(--theme-background-dark)) 0%, rgb(var(--theme-background-light)) 100%);
  margin: 0;
  position: relative;
  min-height: var(--max-height);
  min-height: -webkit-fill-available;
  overflow: hidden;
  font-family: 'Montserrat', sans-serif;
  color: rgba(var(--theme-font-colour), 0.8);
  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    background-image:
    radial-gradient(at 75% 24%, rgba(155, 194, 238, 0.3) 0px, transparent 50%),
    radial-gradient(at 100% 0%, rgba(151, 103, 228, 1) 0px, transparent 50%),
    radial-gradient(at 85% 51%, rgba(78, 108, 244, 0.3) 0px, transparent 50%),
    radial-gradient(at 0% 27%, rgba(151, 102, 229, 0.3) 0px, transparent 50%),
    radial-gradient(at 29% 10%, rgba(73, 152, 248, 1) 0px, transparent 50%),
    radial-gradient(at 42% 47%, rgba(180, 121, 251, 0.3) 0px, transparent 50%),
    radial-gradient(at 60% 7%, rgba(134, 234, 232, 1) 0px, transparent 50%);
    background-size: 100% 50%;
    background-repeat: no-repeat;
    z-index: -2;
    opacity: var(--theme-bg-accent-opacity);
  }
}

#root {
  height: var(--max-height);
  width: 100vw;
  min-height: var(--max-height);
  min-height: -webkit-fill-available;
  min-width: 100vw;
  min-width: -webkit-fill-available;
}

.content {
  max-width: 1440px;
  max-height: var(--max-height);
  margin: auto;
}

.nav {
  max-width: 250px;
}

@media (max-width: 639px) {
  .nav {
    max-width: unset;
    .profile-icon,
    .profile-icon.pi-sm {
      display: none;
    }
  }
}

.apy-card table tr::before {
  content: "";
}

.card {
  @include border-radius(8px);
  &.frost {
    box-shadow: inset 0 0 8px 0 rgba(255, 255, 255, 0.03);
    background-color: rgba(var(--theme-card-bg), var(--theme-card-opacity));
    backdrop-filter: blur(3px);

    &.modal-frost {
      background-color: rgba(var(--theme-card-bg), 0.2);
    }
    
    &::before {
      pointer-events: none;
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 8px;
      border: 1px solid transparent;
      background: linear-gradient(135deg,
      rgba(236, 239, 241, var(--theme-card-border-opacity)),
      rgba(236, 239, 241, 0)) border-box;
      mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
      mask-composite: exclude;
    }
    &::after {
      pointer-events: none;
      content: "";
      z-index: 1;
      margin-top: 0;
      margin-bottom: 0;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0));
      height: 1px;
    }
  }
}

.frosted-bg {
  box-shadow: inset 0 0 8px 0 rgba(255, 255, 255, 0.03);
  background-color: rgba(var(--theme-card-bg), var(--theme-card-opacity));
  backdrop-filter: blur(3px);
  overflow: hidden;

  &::before {
    pointer-events: none;
    content: "";
    position: absolute;
    inset: 0;
    border: 1px solid transparent;
    background: linear-gradient(135deg,
    rgba(236, 239, 241,0.1),
    rgba(236, 239, 241,0)) border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
  }
  &::after {
    pointer-events: none;
    content: "";
    z-index: 1;
    margin-top: 0;
    margin-bottom: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0));
    height: 1px;
  }
}
@media (max-width: 639px) {
  .frosted-bg {
    backdrop-filter: blur(12px);
  }
}


.profile-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  & > img {
    height: 120px;
    width: 120px;
    pointer-events: none;
    z-index: 1;
  }
  &::before {
    content: '';
    position: absolute;
    // top: -2px;
    width: 124px;
    height: 124px;
    @include border-radius(100%);
    background: linear-gradient(140deg, rgb(var(--theme-blue-gradient-start)) 0%, rgb(var(--theme-blue-gradient-end)) 100%);
    box-shadow: rgba(var(--theme-blue-gradient-start), 0.2) 0px 4px 10px 0px;
  }
  &.pi-sm {
    & > img {
      height: 60px;
      width: 60px;  
      min-height: 60px;
      min-width: 60px;  
    }
    &::before {
      height: 64px;
      width: 64px;  
      min-height: 64px;
      min-width: 64px;  
    }
  }
  &.pi-xs {
    & > img {
      height: 40px;
      width: 40px;  
      min-height: 40px;
      min-width: 40px;  
    }
    &::before {
      height: 42px;
      width: 42px;  
      min-height: 42px;
      min-width: 42px;  
    }
  }
}

.about {
  .profile-icon {
    & > img {
      height: auto;
      width: 100%;
      pointer-events: none;
      @include border-radius(8px);
    }
    &::before {
      content: '';
      z-index: -1;
      position: absolute;
      // top: -4px;
      height: calc(100% + 8px);
      width: calc(100% + 8px);
      @include border-radius(10px);
      background: linear-gradient(140deg, rgb(var(--theme-blue-gradient-start)) 0%, rgb(var(--theme-blue-gradient-end)) 100%);
      box-shadow: rgba(var(--theme-blue-gradient-start), 0.2) 0px 4px 10px 0px;
    }  
  }
}

.welcome {
  &.card {
    h1 {
      max-width: 420px;
    }
  }
}

.button {
  @include border-radius(9001px);
  transition: all 0.2s ease 0s;
  padding: 6px 12px 6px;
  height: 38px;
  max-height: 38px;
  min-width: unset;
  text-align: center;
  overflow: hidden;
  position: relative;
  box-shadow: rgba(var(--theme-blue-gradient-start), 0.2) 0px 4px 10px 0px;


  &:before {
    content: '';
    transition: all 0.2s ease 0s;
    z-index: -1;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    opacity: 1;
    background-color: rgba(var(--theme-accent-blue), 1);
    background: linear-gradient(140deg, rgb(var(--theme-blue-gradient-start)) 0%, rgb(var(--theme-blue-gradient-end)) 100%);
  }

  &:hover:not([disabled]) {
    filter:brightness(110%);
    cursor: pointer;
  }

  &.btn-blue-gradient:not([disabled]) {
    box-shadow: rgba(var(--theme-blue-gradient-start), 0.2) 0px 4px 10px 0px;
    &:before {
      background-color: rgba(var(--theme-blue-gradient-start), 1);
      background: linear-gradient(140deg, rgba(var(--theme-blue-gradient-start), 1) 0%, rgba(var(--theme-blue-gradient-end), 1) 75%);
    }
  }
  &.btn-pink-gradient:not([disabled]) {
    box-shadow: rgba(var(--theme-pink-gradient-start), 0.2) 0px 4px 10px 0px;
    &:before {
      background-color: rgba(var(--theme-pink-gradient-start), 1);
      background: linear-gradient(140deg, rgba(var(--theme-pink-gradient-start), 1) 0%, rgba(var(--theme-pink-gradient-end), 1) 100%);
    }
  }
  &.btn-purple-gradient:not([disabled]) {
    box-shadow: rgba(var(--theme-purple-gradient-start), 0.2) 0px 4px 10px 0px;
    &:before {
      background-color: rgba(var(--theme-purple-gradient-start), 1);
      background: linear-gradient(140deg, rgba(var(--theme-purple-gradient-start), 1) 0%, rgba(var(--theme-purple-gradient-end), 1) 75%);  
    }
  }

  &.btn-ghost:not([disabled]) {
    box-shadow: none;
    background-color: rgba(255,255,255,0);
    background: rgba(255,255,255,0);
    &:before {
      background-color: rgba(255,255,255, 0.0);
      background: rgba(255,255,255,0.0);
      // background: linear-gradient(140deg, rgba(var(--theme-purple-gradient-start), 1) 0%, rgba(var(--theme-purple-gradient-end), 1) 75%);  
    }
    &:hover {
      background-color: rgba(255,255,255,0.1);
      background: rgba(255,255,255,0.1);
      &:before {
        background-color: rgba(255,255,255, 0.1);
        background: rgba(255,255,255,0.1);
      }
  
    }
  }

  &[disabled] {
    cursor: default;
    background: rgba(255,255,255,0.1);
    color: rgba(255,255,255,0.1);
    &:before {
      background: rgba(255,255,255,0.1);
      opacity: 1;
    }
  }

  &.btn-large {
    padding: 11px 24px 12px;
    height: 48px;
    max-height: 48px;  
  }
}

.nav-link.active {
  position: relative;
  &:before, :after {
    content: '';
    left: 0px;
    top: 0px;
    bottom: 0px;
    width: 4px;
    position: absolute;
    background-color: rgba(var(--theme-blue-gradient-start), 1);
    background: rgba(var(--theme-blue-gradient-start), 1);
    background: linear-gradient(140deg, rgba(var(--theme-purple-gradient-start), 1) 0%, rgba(var(--theme-purple-gradient-end), 1) 75%);  
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  &:before {
    filter: blur(3px);
  }
}
@media (max-width: 639px) {
  .nav-link.active {
    &:before, :after {
      content: '';
      left: 0px;
      top: unset;
      bottom: 0px;
      right: 0px;
      width: unset;
      height: 4px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 0px;
      border-top-left-radius: 8px;
    }
  }
}


h1.neon-text {
  animation: flicker 1.5s infinite alternate;     
}
.neon-text {
  color: #fff;
  font-family: "Varela Round", sans-serif;
  text-shadow:
    0 0 7px #fff,
    0 0 10px #fff,
    0 0 21px #fff,
    0 0 42px #FE1DAD,
    0 0 82px #FE1DAD,
    0 0 92px #FE1DAD;
  }

@keyframes flicker {
  0%, 18%, 22%, 25%, 53%, 57%, 100% {
    text-shadow:
    0 0 4px #fff,
    0 0 11px #fff,
    0 0 19px #fff,
    0 0 40px #FE1DAD,
    0 0 80px #FE1DAD,
    0 0 90px #FE1DAD;
    }
  20%, 24%, 55% {        
    text-shadow: none;
  }    
}

.chart-con {
  max-height: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chart-wrap {
  position: relative;
  max-height: 250px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & .chart-glow {
    z-index: -1;
    height: 100%;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    filter: blur(12px);
    opacity: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
  }
}

.pill {
  position: relative;
  overflow: hidden;
  @include border-radius(9001px);
  box-shadow: rgba(var(--theme-pill-colour), 0) 0px 4px 10px 0px;
  color: rgba(var(--theme-pill-colour), 1);
  // height: 28px;
  max-height: 28px;
  &:before {
    content: '';
    transition: all 0.2s ease 0s;
    z-index: -1;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    opacity: 1;
    background-color: rgba(var(--theme-pill-colour), 0.3);
  }
}

.t-accent {
  color: rgba(var(--theme-pill-colour));
}

.modal-close {
  color: rgba(var(--theme-pill-colour), 1);
}

body::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

body::-webkit-scrollbar-track {
  background-color: #fff;
}

body::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

body::-webkit-scrollbar-button {
  display:none;
}