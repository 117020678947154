// customised version of:
// https://codepen.io/sarazond/pen/LYGbwj

@function multiple-box-shadow ($n) {
  $value: '#{random(2000)}px #{random(2000)}px #FFF';
  @for $i from 2 through $n {
    $value: '#{$value} , #{random(2000)}px #{random(2000)}px #FFF';
  }

  @return unquote($value);
}
$shadows-small: multiple-box-shadow(400);
$shadows-medium: multiple-box-shadow(200);
$shadows-big: multiple-box-shadow(100);

#star-bg-con {
  position: relative;
  height: 100%;
  width: 1920px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  mask-image: linear-gradient(
    90deg,
    transparent 0%,
    black 10%,
    black 90%,
    transparent 100%
  );
  -webkit-mask-image: linear-gradient(
    90deg,
    transparent 0%,
    black 10%,
    black 90%,
    transparent 100%
  );
}

#star-bg {
  position: relative;
  height: 100%;
  width: 1920px;
  background: linear-gradient(
    184deg,
    rgba(78, 109, 244, 0.3) 0%,
    rgba(78, 109%, 244, 0) 100%),
    radial-gradient(ellipse at bottom, rgb(var(--theme-background-dark)) 0%, rgb(var(--theme-background-light)) 100%);
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    height: 100%;
    background-image:
    radial-gradient(at 75% 24%, rgba(155, 194, 238, 0.3) 0px, transparent 50%),
    radial-gradient(at 100% 0%, rgba(151, 103, 228, 1) 0px, transparent 50%),
    radial-gradient(at 85% 51%, rgba(78, 108, 244, 0.3) 0px, transparent 50%),
    radial-gradient(at 0% 27%, rgba(151, 102, 229, 0.3) 0px, transparent 50%),
    radial-gradient(at 29% 10%, rgba(73, 152, 248, 1) 0px, transparent 50%),
    radial-gradient(at 42% 47%, rgba(180, 121, 251, 0.3) 0px, transparent 50%),
    radial-gradient(at 60% 7%, rgba(134, 234, 232, 1) 0px, transparent 50%);
    background-size: 100% 50%;
    background-repeat: no-repeat;
    z-index: -1;
    opacity: var(--theme-bg-accent-opacity);
  }
}

#st-star-bg {
  position: absolute;
  height: 100vh;
  width: 1920px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  mask-image: linear-gradient(#000 0%, transparent 60%);
  z-index: -2;
}

@keyframes animStar {
  from {
    transform: translateY(-2000px);
  }
  to {
    transform: translateY(0px);
  }
};

#st-stars, #stars {
  filter: blur(1px);
  width: 1px;
  height: 1px;
  background: transparent;
  box-shadow: $shadows-small;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadows-small;
  }
}

#st-stars2, #stars2 {
  filter: blur(1px);
  width: 2px;
  height: 2px;
  background: transparent;
  box-shadow: $shadows-medium;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-medium;
  }
}

#st-stars3, #stars3 {
  filter: blur(0.8px);
  width: 3px;
  height: 3px;
  background: transparent;
  box-shadow: $shadows-big;

  &:after {
    content: " ";
    position: absolute;
    top: 2000px;
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shadows-big;
  }
}

#stars, #stars2, #stars3 {
  animation: animStar 50s linear infinite;
}

@keyframes rocket {
  0%, 100% {
    transform: translate(-50%, 4px);
  }
  50% {
    transform: translate(-50%, -4px);
  }
};

@keyframes rocketJet {
  0%, 100% {
    bottom: -209px;
    height: 204px;
  }
  50% {
    bottom: -197px;
    height: 192px;
  }
};

@keyframes rocketLeave {
  0% {
    transform: translate(-50%, 0px);
  }
  20% {
    transform: translate(-50%, 50px);
  }
  100% {
    transform: translate(-50%, -4200px);
  }
};

@keyframes rocketJetLeave {
  0% {
    bottom: -205px;
    height: 200px;
  }
  20% {
    bottom: -255px;
    height: 255px;
  }
  100% {
    bottom: -4405px;
    height: 4400px;
  }
};

#rocket {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & svg {
    transform: rotate(-90deg);
    font-size: 3rem;
  }
  animation: rocket 0.8s ease infinite;

  &::before, &::after {
    z-index: -1;
    content: '';
    position: absolute;
    bottom: -205px;
    left: 50%;
    transform: translateX(-50%);
    width: 0.8rem;
    height: 200px;
    background: linear-gradient(180deg,
    rgb(var(--theme-blue-gradient-start)) 0%,
    rgb(var(--theme-blue-gradient-end)) 60%,
    rgba(var(--theme-blue-gradient-end), 0) 100%);
    animation: rocketJet 0.8s ease infinite;
  }
  &::after {
    width: 0.5rem;
    filter: blur(20px);
  }
}

#rocket.finish {
  animation: rocketLeave 2s ease;
  animation-iteration-count: 1;
  &::before, &::after {
    animation: rocketJetLeave 2s ease;
    animation-iteration-count: 1;
  }
}

@property --wipe-position {
  syntax: "<percentage>";
  inherits: false;
  initial-value: 100%;
}

#star-bg {
  --wipe-position: 100%;
  --gradient-length: 30%;
  mask-image: linear-gradient(
    to bottom,
    black var(--wipe-position),
    transparent calc(var(--wipe-position) + var(--gradient-length)),
    transparent
  );
  -webkit-mask-image: linear-gradient(
    to bottom,
    black var(--wipe-position),
    transparent calc(var(--wipe-position) + var(--gradient-length)),
    transparent
  );
  transition: --wipe-position 600ms cubic-bezier(0, 0.55, 0.45, 1);
}

#star-bg.finish {
  --wipe-position: calc(-1 * var(--gradient-length));
}

#loading {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 80px;
  transition: all 0.2s ease 0s;
}