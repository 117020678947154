.zm-dark {
  --theme-font-colour: 255, 255, 255;
  --theme-background-dark: 8, 9, 13;
  --theme-background-light: 21, 29, 37;
  --theme-accent-blue: 44, 226, 230;
  --theme-accent-blue-dark: 9, 75, 179;
  --theme-accent-pink: 246, 0, 157;
  --theme-accent-pink-dark: 133, 0, 106;
  --theme-accent-purple: 121, 29, 148;
  --theme-accent-purple-dark: 133, 0, 106;
  --theme-bg-accent-opacity: 0.7;
  --theme-card-bg: 255, 255, 255;
  --theme-card-opacity: 0.05;
  --theme-card-border-opacity: 0.1;
  --theme-pill-colour: var(--theme-accent-blue);

  --theme-blue-gradient-start: 96, 223, 205;
  --theme-blue-gradient-end: 30, 154, 254;
  --theme-pink-gradient-start: 225, 0, 255;
  --theme-pink-gradient-end: 127, 0, 255;
  --theme-purple-gradient-start: 110, 114, 252;
  --theme-purple-gradient-end: 173, 29, 235;
}

.zm-light {
  --theme-font-colour: 15, 23, 42;
  --theme-background-dark: 148, 163, 184;
  --theme-background-light: 203, 213, 225;
  --theme-accent-blue: 14, 165, 233;
  --theme-accent-blue-dark: 9, 75, 179;
  --theme-accent-pink: 246, 0, 157;
  --theme-accent-pink-dark: 133, 0, 106;
  --theme-accent-purple: 121, 29, 148;
  --theme-accent-purple-dark: 133, 0, 106;
  --theme-bg-accent-opacity: 1;
  --theme-card-bg: 255, 255, 255;
  --theme-card-opacity: 0.15;
  --theme-card-border-opacity: 0.3;
  --theme-pill-colour: var(--theme-accent-blue-dark);

  --theme-blue-gradient-start: 96, 223, 205;
  --theme-blue-gradient-end: 30, 154, 254;
  --theme-pink-gradient-start: 225, 0, 255;
  --theme-pink-gradient-end: 127, 0, 255;
  --theme-purple-gradient-start: 110, 114, 252;
  --theme-purple-gradient-end: 173, 29, 235;
}